import { Box, Button, Center, Overlay, Paper, Stack, Text } from "@mantine/core"
import { AlertTriangle } from "tabler-icons-react"

const FullscreenWarning = ({toggleFullscreen}) => {
    return (
        <>
            <Box sx={(theme) => ({
                position: 'absolute',
                top: 0, bottom: 0, right: 0, left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(3px)'
            })}>

            </Box>
            <Box sx={(theme) => ({
                position: 'absolute',
                top: 0, bottom: 0, right: 0, left: 0,
            })}>
                <Center style={{ height: '100vh' }}>
                    <Paper p={"md"} radius={"md"}>
                        <Stack align={"center"}>
                            <AlertTriangle color="orange" size={"30%"} />
                            <Text weight={"500"} size={"xl"}>Layar Anda wajib fullscreen untuk melanjutkan.</Text>
                            <Button size="lg" onClick={() => toggleFullscreen()}>Ubah fullscreen</Button>
                        </Stack>
                    </Paper>
                </Center>
            </Box>
        </>
    )
}

export default FullscreenWarning