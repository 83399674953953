import { Card, Center } from "@mantine/core"
import { useFullscreen, useInterval, useTimeout } from "@mantine/hooks"
import { hideNotification, showNotification, updateNotification } from "@mantine/notifications"
import axios from "axios"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import BoxExam from "../../components/exam/BoxExam"
import FullscreenWarning from "../../components/exam/FullscreenWarning"
import QuestionDrawer from "../../components/exam/QuestionDrawer"
import RemindExam from "../../components/exam/RemindExam"
import SubtesConfirm from "../../components/exam/SubtestConfirm"
import ThemeToggler from "../../components/exam/ThemeToggler"
import TutorialExam from "../../components/exam/TutorialExam"
import { api_url } from "../../constant/api"
import AuthContext from "../../context/AuthContext"
import secureStorage from "../../helper/secureStorage"
import ConnectivityStatus from "./ConnectivityStatus"
import ExamTemplate from "./examTemplate/ExamTemplate"
import { isMobile } from "react-device-detect"
import ExamLoader from "../../components/exam/ExamLoader"
import delayCustom from "../../helper/delay"

var globalExamType = {
    abbreviation: '',
    examSubtest: [],
    name: '',
    id: '',
}

var globalExam = {
    name: '',
    abbreviation: '',
    examSubtest: [],
    id: '',
}

var globalAnswer = {
    exam_subtest_id: null,
    exam_subtest_question_id: null,
    answers: [],
    time: 0,
};

const ExamPage = () => {
    const [connectivity, setConnectivity] = useState("online")
    const { state, exam, jwt } = useContext(AuthContext)
    const [jwtLocally, setJwtLocally] = useState("")
    const [confirm, setConfirm] = useState(false)
    const [seconds, setSeconds] = useState(0)
    const [unlimitedTime, setUnlimitedTime] = useState(false)
    // const interval = useInterval(() => { setSeconds((s) => s - 1); secureStorage.set("time", seconds) }, 1000)
    // const [listQuestionOpen, setListQuestionOpen] = useState(false)
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [nullAnswers, setNullAnswers] = useState(0)
    const [activeQuestion, setActiveQuestion] = useState(-1)
    const { toggle, fullscreen } = useFullscreen()
    const [cookies, _, removeCookie] = useCookies(['psychology'])
    const [isPhone, setIsPhone] = useState(false)
    const [iniInterval, setIniInterval] = useState(null)
    const [saveInterval, setSaveInterval] = useState(null)
    const [loading, setLoading] = useState(true)
    const [noExample, setNoExample] = useState(false)

    const navigate = useNavigate()

    // let saveInterval = useInterval(() => {
    //     console.log(jwt)
    //     if (connectivity == "offline") return;
    //     if (state.userState.status == "example") return;
    //     if (state.userState.status == "reminder") return;
    //     // interalSaveSubtestAnswer()
    // }, 5000)

    const getQuestionExam = async () => {
        let res = await axios.get(`${api_url}/exam/subtest/${state.userState.exam_subtest_id}/question`, {
            headers: {
                "Authorization": `Bearer ${jwt.jwtToken}`
            }
        })
        console.log(res.data?.data?.examSubtestQuestion)
        secureStorage.setCompress("que", res.data?.data?.examSubtestQuestion)
        let ans = secureStorage.getDecompress("ans")
        if (!ans) {
            secureStorage.setCompress("ans", [])
        }
    }

    const updateAnswers = (ans) => {
        if (ans[-1]) return
        setAnswers(ans)
        secureStorage.setCompress("ans", ans)
        countNullAnswer();
    }

    const countNullAnswer = () => {
        let tmp = 0
        questions.map((data, key) => {
            if (answers[key] == null) {
                tmp++
            }
        })

        setNullAnswers(tmp)
    }

    const intervalSaveSubtestAnswer = () => {
        setConnectivity("pending")
        if (state.userState.status == "example" || state.userState.status == "reminder" || state.userState.status == "reminder-started" || !state.userState.status) {
            setConnectivity("online")
            return
        }
        console.log("global answer", globalAnswer)
        console.log("global examType", globalExamType)
        axios.post(`${api_url}/exam/user/temp`, {
            answer: JSON.stringify(globalAnswer.answers),
            exam_subtest_id: globalAnswer.exam_subtest_id,
            exam_subtest_question_id: globalAnswer.exam_subtest_question_id,
            exam_session_id: globalExamType.find((data) => data.is_done == 0).id,
            time: globalAnswer.time
        }, {
            headers: {
                "Authorization": `Bearer ${jwt.jwtToken}`
            }
        }).then((res) => {
            setConnectivity("online")
        }).catch((err) => {
            console.log(err)
            setConnectivity("online")
        })
    }

    const saveSubtestAnswer = async () => {
        clearInterval(iniInterval)
        clearInterval(saveInterval)
        setIniInterval(null);
        setSaveInterval(null);
        setLoading(true)
        if (state.userState.status == "example" || state.userState.status == "reminder" || state.userState.status == "reminder-started" || !state.userState.status) return
        setConnectivity("pending")
        try {
            var answerType = null
            try {
                for (let index = 0; index < answers.length; index++) {
                    if (Array.isArray(answers[index])) {
                        answerType = answers[index].find(i => i != null)?.exam_answer_type
                        if(answerType != null) break;
                    } else {
                        answerType = answers[index]?.exam_answer_type
                        if(answerType != null) break;
                    }
                }
            } catch (e) {
                answerType = null
            }
            let res = await axios.post(`${api_url}/exam/user/answer${answerType == "MULTIPG" && answerType != null ? "/alternative" : ""}`, {
                answers: answers,
                exam_subtest_id: state.userState.exam_subtest_id,
                // type: globalExam.abbreviation,
                dont_validate: exam.userExam.examSubtest[exam.examStatus.currentSubtest]?.parameter?.dont_validate ? true : false,
                exam_type_id: globalExam.id,
                is_final: exam.examStatus.currentSubtest == exam.examStatus.subtestCount ? 1 : 0
            }, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })
            showNotification({
                title: 'berhasil disimpan'
            })
        } catch (err) {
            console.log(err.response)
            axios.post(`${api_url}/error-log`, {
                title: 'save user answer',
                description: err?.message,
                root: JSON.stringify(err?.response)
            })
            // showNotification({
            //     id: 'error-notification',
            //     title: 'gagal disimpan, mencoba lagi.. mohon menunggu',
            //     color: 'red',
            //     disallowClose: true
            // })
            // await delayCustom(2000)
            // hideNotification('error-notification')
            // return saveSubtestAnswer()
        }

        if (exam.examStatus.currentSubtest == exam.examStatus.subtestCount) {
            await exam.refreshExamTypes();

            let availableExamLeft = exam.examTypes.filter((data) => data.is_done == 0)
            console.log("availableExamLeft", availableExamLeft)

            if (availableExamLeft.length > 0) {
                let res = await exam.refreshUserExam();
                console.log(res)
                await axios.post(`${api_url}/exam/user/temp`, {
                    status: 'switching',
                    exam_subtest_id: res.examSubtest[0].id,
                    answer: null,
                }, {
                    headers: {
                        "Authorization": `Bearer ${jwt.jwtToken}`
                    }
                })
                await state.refreshState()
                secureStorage.del("que")
                secureStorage.del("ans")
                secureStorage.del("time")
                navigate('/user/welcome')
                setLoading(false)
                return
            }

            try {
                await axios.post(`${api_url}/exam/user/temp`, {
                    status: 'completed',
                    exam_subtest_id: exam.userExam.examSubtest[exam.examStatus.currentSubtest].id,
                    ended_at: (new Date()),
                }, {
                    headers: {
                        "Authorization": `Bearer ${jwt.jwtToken}`
                    }
                })
                await state.refreshState()
                secureStorage.setCompress("que", [])
                secureStorage.setCompress("ans", [])
            } catch (err) {
                console.log(err)
            }
            removeCookie('psychology', {
                path: '/'
            })
            return
        }

        let status = 'example'
        // check if next subtest have reminder

        if (exam.userExam.examSubtest[exam.examStatus.currentSubtest + 1]?.examSubtestReminder !== null) {
            status = 'reminder'
        }

        try {
            await axios.post(`${api_url}/exam/user/temp`, {
                status: status,
                exam_subtest_id: exam.userExam.examSubtest[exam.examStatus.currentSubtest + 1].id,
                exam_session_id: globalExamType.find((data) => data.is_done == 0).id,
                answer: JSON.stringify([]),
                time: -1
            }, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })
            await state.refreshState()
            secureStorage.setCompress("que", [])
            secureStorage.setCompress("ans", [])
            setAnswers([])
            setQuestions([])
            // setLoading(false)
        } catch (err) {
            console.log(err)
        }

        setConnectivity("online")
        // setLoading(false)
    }

    const examInitialize = async () => {
        setLoading(true)
        if (!state.isReady) return
        if (!state.userState.id) return navigate('/user/welcome')

        if (!state.userState.started_at && !state.userState.ended_at) return
        setConnectivity("pending")
        // console.log(moment(state.userState.started_at).toDate())
        // console.log(moment(state.userState.ended_at).toDate())
        // let diff = moment.duration(moment(state.userState.ended_at).diff(moment()))
        let sec = secureStorage.get("time");

        // setSeconds(diff.asSeconds().toFixed(0))

        let que = secureStorage.getDecompress('que')
        let ans = secureStorage.getDecompress('ans')
        if (!ans) {
            secureStorage.setCompress('ans', [])
            ans = [];
        }

        try {
            let tempAnsJson = await axios.get(`${api_url}/exam/user/get-temp`, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })

            if (tempAnsJson.data?.data?.time && tempAnsJson.data?.data?.time > 0 && sec == null) {
                setSeconds(tempAnsJson.data?.data?.time)
                if (tempAnsJson.data?.data?.time <= -100) {
                    setUnlimitedTime(true)
                }
            } else {
                if (tempAnsJson.data?.data?.time <= -100) {
                    setUnlimitedTime(true)
                    setSeconds(tempAnsJson.data?.data?.time)
                } else {
                    setSeconds(sec ?? 200)
                }
            }

            let tempAns = JSON.parse(tempAnsJson.data?.data?.answer)
            if (Array.isArray(tempAns) && tempAns?.length > answers.length && tempAnsJson.data?.data?.exam_subtest_id == state.userState.exam_subtest_id) {
                setAnswers(tempAns)
                secureStorage.setCompress("ans", tempAns)
            } else {
                setAnswers(ans)
            }
        } catch (e) {
            console.error(e)
            setAnswers(ans)
            if (sec <= -100) {
                setUnlimitedTime(true)
            }
            setSeconds(sec ?? 200)
        }

        if (!que) {
            await getQuestionExam();
            que = secureStorage.getDecompress('que')
            setQuestions(que)
        }

        setQuestions(que)
        setActiveQuestion(0)
        setConnectivity("online")
        setSaveInterval(setInterval(intervalSaveSubtestAnswer, 4000))
        setLoading(false)
        countNullAnswer()
    }

    // this function has moved to below saveExamSubtestAnswer
    // useEffect(() => {
    //     if (seconds < -1) clearInterval(iniInterval)
    // }, [seconds])

    useEffect(() => {
        console.log(fullscreen)
    }, [fullscreen])

    useEffect(() => {
        if (questions.length == 0) return
        console.log(questions)
        let actQueIndex = questions.findIndex((val) => val.id == state.userState.examSubtestQuestion.id)
        setActiveQuestion(actQueIndex)
    }, [questions])

    useEffect(() => {
        if (state.userState.status == "completed") {
            removeCookie('psychology', {
                path: '/'
            })
            return
        }
    }, [state])

    useEffect(() => {
        if (!jwt.isReady) return
        setJwtLocally(jwt.jwtToken)
    }, [jwt.isReady])



    useEffect(() => {
        examInitialize();
    }, [state.isReady])

    useEffect(() => {
        if (!exam.isReady) return
        globalExamType = exam.examTypes
        globalExam = exam.userExam
        console.log("ini exam", exam)
    }, [exam.isReady])

    useEffect(() => {
        if (state.userState.status == "example" || state.userState.status == "reminder" || state.userState.status == "reminder-started") return
        if (seconds < 0 && seconds > -99) {
            console.log("mashok dong")
            saveSubtestAnswer()
            clearInterval(iniInterval)
            clearInterval(saveInterval)
            setSaveInterval(null);
            setIniInterval(null);
            return
        }
        if (iniInterval == null && seconds > 0) {
            setIniInterval(setInterval(() => {
                setSeconds((s) => {
                    secureStorage.set("time", s)
                    globalAnswer.time = s
                    return s - 1
                })
            }, 1000))
            return
        }

        if (iniInterval == null && seconds <= -100) {
            setIniInterval(setInterval(() => {
                setSeconds((s) => {
                    secureStorage.set("time", s)
                    globalAnswer.time = s
                    return s - 1
                })
            }, 1000))
            return
        }

        // saveInterval.start()
    }, [seconds, setSeconds])

    useEffect(() => {
        if (!Array.isArray(answers)) return
        if (answers.length == 0) return
        globalAnswer.answers = answers;
        console.log("global answer", globalAnswer)
        console.log("ini answer", answers)
        countNullAnswer();
    }, [answers])

    useEffect(() => {
        globalAnswer.exam_subtest_question_id = questions[activeQuestion]?.id
        globalAnswer.exam_subtest_id = questions[activeQuestion]?.exam_subtest_id
    }, [activeQuestion])

    useEffect(() => {
        if (!jwt.isReady) return
        // const saveInterval = setInterval(() => {
        //     interalSaveSubtestAnswer()
        // }, 5000);

        // return () => clearInterval(saveInterval)
    }, [jwt.isReady])

    useEffect(() => {
        // start()
        // setSeconds(300)
        // interval.start()
        // return interval.stop()
        window.addEventListener("offline", (e) => {
            console.log("offline")
            setConnectivity("offline")
        })
        window.addEventListener("online", (e) => {
            setConnectivity("online")
            console.log("online")
        })

        console.log(isMobile, "is mobile")
        if (isMobile) {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }

    }, [])

    return (
        <Card sx={(theme) => ({ height: '100vh', overflowX: 'hidden', backgroundColor: theme.colors.orange[1] })}
            onContextMenu={(e) => e.preventDefault()}
            style={{
                userSelect: 'none',
                msUserSelect: 'none',
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                WebkitTouchCallout: 'none',
            }}
        >
            {state.userState.status == "started" && (
                // <BoxExam setConfirm={setConfirm} seconds={seconds} questions={questions}
                //     activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion}
                //     answers={answers} updateAnswers={updateAnswers} />
                <ExamTemplate isPhone={isPhone} setConfirm={setConfirm} seconds={seconds} questions={questions}
                    activeQuestion={activeQuestion} setActiveQuestion={setActiveQuestion}
                    answers={answers} updateAnswers={updateAnswers} psikotes={exam.userExam} nullAnswers={nullAnswers} />
            )}
            <Center style={{ width: '100%', height: '100vh' }}>

                {state.userState.status == "example" && (
                    <TutorialExam isPhone={isPhone} setLoading={setLoading} loading={loading} noExample={noExample} />
                )}
                {(state.userState.status == "reminder" || state.userState.status == "reminder-started") && (
                    <RemindExam isPhone={isPhone} seconds={seconds} setLoading={setLoading} />
                )}
            </Center>
            {/* <div style={{ position: 'absolute', bottom: 15, left: 15 }} >
                <ThemeToggler listQuestionOpen={listQuestionOpen} setListQuestionOpen={setListQuestionOpen} />
            </div> */}
            <SubtesConfirm open={confirm} setOpen={setConfirm}
                saveAnswer={saveSubtestAnswer} countNullAnswer={countNullAnswer} nullAnswers={nullAnswers} />
            {/* <QuestionDrawer open={listQuestionOpen} setOpen={setListQuestionOpen}
                questions={questions} activeQuestion={activeQuestion}
                setActiveQuestion={setActiveQuestion} answers={answers} /> */}
            {!fullscreen && !isPhone ? <FullscreenWarning toggleFullscreen={toggle} /> : ''}
            <ConnectivityStatus connectivity={connectivity} />
            <ExamLoader open={loading} setOpen={setLoading} setNoExample={setNoExample} />
        </Card>
    )
}


export default ExamPage