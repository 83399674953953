import { Center, Loader, Modal, Stack, Text, Title } from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useState } from "react";
import { useEffect } from "react";

const ExamLoader = ({ open, setOpen, setNoExample }) => {
    const [seconds, setSeconds] = useState(0);
    const interval = useInterval(() => setSeconds((s) => s + 1), 1000);

    useEffect(() => {
        if (!open) {
            interval.stop();
            setSeconds(0);
        } else {
            interval.start();
        }
    }, [open]);

    useEffect(() => {
        console.log("time for loading", seconds)
        if (seconds >= 10) {
            interval.stop();
            setSeconds(0);
            setOpen(false);
            setNoExample(true)
        }
    }, [seconds]);


    return (
        <>
            <Modal
                opened={open}
                centered
                closeOnEscape={false}
                withCloseButton={false}
                closeOnClickOutside={false}
                overlayOpacity={0.5}
                overlayBlur={3}
            >
                <Stack align={"center"}>
                    <Title order={2}>Sedang Memuat Data</Title>
                    <Loader size={"lg"} variant="dots" />
                    <Text>Harap menunggu</Text>
                </Stack>
            </Modal>
        </>
    )
}

export default ExamLoader;