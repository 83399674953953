import { Box, Button, Card, Divider, Group, Stack, Tabs, Text, Title } from "@mantine/core"
import axios from "axios"
import { useEffect } from "react"
import { useContext } from "react"
import { useState } from "react"
import { InfoCircle, AlertCircle } from "tabler-icons-react"
import { api_url } from "../../constant/api"
import AuthContext from "../../context/AuthContext"
import secureStorage from "../../helper/secureStorage"
import parse from "html-react-parser"
import { useInterval } from "@mantine/hooks"

const RemindExam = ({ setLoading }) => {
    const [activeTab, setActiveTab] = useState("tentang")
    const { exam, jwt, state } = useContext(AuthContext)
    const [reminderTime, setReminderTime] = useState(0)
    const [reminderSubtes, setReminderSubtes] = useState({
        reminder_text: '',
        reminder_duration: '',
        greeting_text: '',
    })
    const [timeReady, setTimeReady] = useState(false)
    const interval = useInterval(() => {
        setReminderTime((s) => {
            secureStorage.set("remt", s)
            return s - 1
        })
    }, 1000)

    const onStartExample = async () => {
        setLoading(true)
        try {
            await axios.post(`${api_url}/exam/user/temp`, {
                status: "example",
                type: exam.userExam.abbreviation,
            }, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })
            await state.refreshState()
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    const onStartTimeReminder = async () => {
        try {
            await axios.post(`${api_url}/exam/user/temp`, {
                status: "reminder-started",
                type: exam.userExam.abbreviation,
            }, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })
            await state.refreshState()
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // setLoading(true)
        if (!exam.isReady) return
        if (exam.examStatus.currentSubtest == null) return
        let time = secureStorage.get("remt")
        console.log("inii time", time)
        if (time == null) {
            console.log("wow")
            time = exam.userExam.examSubtest[exam.examStatus.currentSubtest]?.examSubtestReminder?.reminder_duration
            setReminderTime(time)
            secureStorage.set("remt", time)
            setTimeReady(true)
        } else {
            setReminderTime(time)
            setTimeReady(true)
        }

        setReminderSubtes(exam.userExam.examSubtest[exam.examStatus.currentSubtest]?.examSubtestReminder)
        setLoading(false)
    }, [exam.isReady, exam.examStatus.currentSubtest])

    useEffect(() => {
        if (!state.isReady) return
        if (state.userState.status == "reminder-started" && timeReady) {
            interval.start()
            setLoading(false)
        }

        return interval.stop()
    }, [state.isReady, state.userState.status, timeReady])

    useEffect(() => {
        if (reminderTime < 0) {
            interval.stop()
            secureStorage.del("remt")
            onStartExample()
        }
    }, [reminderTime])



    return (
        <>
            {state.userState.status == "reminder" && (
                <ReminderAlert onStartTimeReminder={onStartTimeReminder} greetingText={reminderSubtes?.greeting_text} />
            )}
            {state.userState.status == "reminder-started" && (
                <ReminderBox content={reminderSubtes?.reminder_text ?? ""} seconds={reminderTime} interval={interval} />
            )}
        </>
    )
}

const ReminderBox = ({ content, seconds }) => {
    const [zeroMinutes, setZeroMinutes] = useState("")
    const [zeroSeconds, setZeroSeconds] = useState("")

    useEffect(() => {
        let min = Math.floor(seconds % 3600 / 60);
        let sec = Math.floor(seconds % 3600 % 60);

        setZeroMinutes(min < 10 ? "0" + min : min)
        setZeroSeconds(sec < 10 ? "0" + sec : sec)
    }, [seconds])


    return (
        <Card shadow={"xl"} withBorder sx={(theme) => ({
            width: '70%',
            backgroundColor: (theme.colorScheme == "dark" ? theme.colors.dark[9] : theme.colors.gray[2]),
            padding: 20,
            borderRadius: '15px'
        })}>
            <Stack spacing={5}>
                <Group position="apart" sx={(theme) => ({
                    backgroundColor: (theme.colorScheme == "dark" ? theme.colors.orange[8] : theme.colors.orange[5]),
                    padding: 10,
                    borderRadius: 15
                })}>
                    <Text size={"xl"} weight={"500"}>Pengingat</Text>
                    <Text weight={700}>Sisa Waktu {zeroMinutes} : {zeroSeconds}</Text>
                </Group>
                <Divider />
                <Box mx={10}>
                    {parse(content)}
                </Box>
                <Divider />
                <Group position="right">
                    <Text>Tab ini akan otomatis tutup saat waktu habis</Text>
                </Group>
            </Stack>
        </Card>
    )
}

const ReminderAlert = ({ onStartTimeReminder, greetingText }) => {
    return (
        <Card shadow={"xl"} withBorder sx={(theme) => ({
            width: '70%',
            backgroundColor: (theme.colorScheme == "dark" ? theme.colors.dark[9] : theme.colors.gray[2]),
            padding: 20,
            borderRadius: '15px'
        })}>
            <Stack spacing={5}>
                <Group position="apart">
                    <Text size={"xl"} weight={"500"}>Pengingat</Text>
                </Group>
                <Divider />
                <Box mx={10}>
                    {parse(greetingText ?? "")}
                </Box>
                <Divider />
                <Group position="right">
                    <Button onClick={onStartTimeReminder} color={"red"}>Selanjutnya</Button>
                </Group>
            </Stack>
        </Card>
    )
}


export default RemindExam