import { Group } from "@mantine/core";
import { useEffect, useState } from "react"
import { io } from "socket.io-client";
import { api_url } from "../../../constant/api";


const LiveStream = () => {
    const [listUser, setListUser] = useState({})

    const socket = io(api_url, {
        query: {
            "accountType": "admin",
            "token": "woew.qoqowi"
        }
    })

    useEffect(() => {
        socket.on("update", data => {
            setListUser(data)
            console.log(data)
        })
    }, [])

    return (
        <>
            <Group>
                {Object.keys(listUser).map((key, idx) => (
                    <div key={idx}>
                        <video src={URL.createObjectURL(new Blob(listUser[key].stream))} ></video>
                    </div>
                ))}
            </Group>
        </>
    )
}

export default LiveStream