import { Box, Button, Stack } from "@mantine/core"
import { useCookies } from "react-cookie"
import { useNavigate } from "react-router-dom"
import MenuButton from "./MenuButton"

const SidebarAdmin = () => {

    const [cookies, _, removeCookie] = useCookies("incipient")
    const navigate = useNavigate()

    const onLogout = () => {
        localStorage.removeItem('profile')
        removeCookie("incipient", {
            path: '/'
        })
        navigate('/access/admin')
    }

    return (
        <>
            <Stack>
                <MenuButton to={"/admin/dashboard"}>Home</MenuButton>
                <MenuButton to={"/admin/akun"}>Akun</MenuButton>
                <MenuButton to={"/admin/akun/admin"}>Admin</MenuButton>
                <MenuButton to={"/admin/psikotes"}>Psikotes</MenuButton>
                <MenuButton to={"/admin/norma"}>Norma Penilaian</MenuButton>
                <MenuButton to={"/admin/laporan"}>Laporan Nilai</MenuButton>
                {/* <MenuButton to={"/admin/livestream"}>Siaran Langsung</MenuButton> */}
            </Stack>
            <div style={{ position: 'absolute', bottom: 15, left: 0, right: 0 }}>
                <Box mx={10}>
                    <Button fullWidth color={"red"} onClick={onLogout} >Keluar</Button>
                </Box>
            </div>
        </>
    )
}

export default SidebarAdmin