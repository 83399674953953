import { useCookies } from "react-cookie"
import encryption from "../helper/encryption"
import const_hasher from "../constant/hasher"
import { createContext, useEffect, useState } from "react"
import { io } from "socket.io-client"
import { api_url } from "../constant/api"

const SocketAdminContext = createContext({
    socketIo: null
})

const AdminContext = ({ children }) => {
    const [cookies, _, __] = useCookies(['incipient'])
    const [socketIo, setSocketIo] = useState(io(api_url, {
        autoConnect: false,
        transports: ['websocket'],
        query: {
            "accountType": "admin",
            "token": encryption.AESdecrypt(cookies['incipient'], const_hasher)
        }
    }))

    useEffect(() => {
        socketIo.connect()
        socketIo.on('connect', (data) => {
            console.log("socket connected");
        })

        return () =>{
            setSocketIo(null)
        }
    }, [])

    return (
        <SocketAdminContext.Provider value={{socketIo: socketIo}}>
            {children}
        </SocketAdminContext.Provider>
    )
}

export {
    AdminContext,
    SocketAdminContext
}