import { Box, Button, Card, Divider, Group, Paper, ScrollArea, Stack, Tabs, Text, TextInput, TypographyStylesProvider } from "@mantine/core"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { InfoCircle, AlertCircle } from "tabler-icons-react"
import { api_url } from "../../constant/api"
import AuthContext from "../../context/AuthContext"
import parse from "html-react-parser"
import moment from "moment/moment"
import secureStorage from "../../helper/secureStorage"

const TutorialExam = ({ isPhone, setLoading, loading, noExample }) => {
    const [activeTab, setActiveTab] = useState("tentang")
    const [exampleQuestion, setExampleQuestion] = useState([])
    const { state, jwt, exam } = useContext(AuthContext)
    const [selectedRadio, setSelectedRadio] = useState(-10)
    const [trueChoice, setTrueChoice] = useState(-10)
    const [selectedCheckbox, setSelectedCheckbox] = useState([])
    const [absoluteTrueEssay, setAbsoluteTrueEssay] = useState("")
    const [revealMultiCorrect, setRevealMultiCorrect] = useState(false)
    const [trueEssay, setTrueEssay] = useState({
        isTrue: false,
        trueIdx: -1,
    })
    const [essayAnswer, setEssayAnswer] = useState("")

    const onStartTimeExam = async () => {
        setLoading(true)
        try {
            let res = await axios.get(`${api_url}/exam/subtest/${state.userState.exam_subtest_id}/question`, {
                headers: {
                    "Authorization": `Bearer ${jwt.jwtToken}`
                }
            })
            console.log(res.data?.data?.examSubtestQuestion)
            secureStorage.setCompress("que", res.data?.data?.examSubtestQuestion)
            secureStorage.setCompress("ans", [])
            secureStorage.set("time", state.userState.examSubtest?.duration)


            // console.log(moment().toJSON())
            // console.log(moment().add(state.userState.examSubtest?.duration, 'seconds').toJSON())

            let status = "started"
            // if (exam.userExam.examSubtest[exam.examStatus.currentSubtest].examSubtestReminder !== null) {
            //     status = "reminder"
            // }
            try {
                await axios.post(`${api_url}/exam/user/temp`, {
                    status: status,
                    exam_subtest_question_id: res.data?.data?.examSubtestQuestion[0].id,
                    type: exam.userExam.abbreviation,
                    started_at: moment().toJSON(),
                    ended_at: moment().add(state.userState.examSubtest?.duration, 'seconds').toJSON()
                }, {
                    headers: {
                        "Authorization": `Bearer ${jwt.jwtToken}`
                    }
                })
                await state.refreshState()
            } catch (err) {
                console.log(err)
            }
            // setLoading(false)
        } catch (err) {
            console.log(err)
        }


    }

    const onCheckboxChange = (index, value) => {
        let temp = selectedCheckbox.slice();
        temp[index] = value;
        console.log(temp)
        setSelectedCheckbox(temp);
    }

    const checkAnswer = (exampleKey, typeAnswer) => {
        if (typeAnswer == "PG") {
            let trueIdx = exampleQuestion[exampleKey].examSubtestChoice.findIndex((val) => val.is_correct == 1)
            setTrueChoice(trueIdx)
        } else if (typeAnswer == "MULTIPG") {
            setRevealMultiCorrect(true)
        }
        else {
            let trueIdx = exampleQuestion[exampleKey].examSubtestEssayKey.findIndex((val) => val.essay_text.toLowerCase() == essayAnswer.toLowerCase())
            console.log(exampleQuestion[exampleKey].examSubtestEssayKey)
            let absoluteTrueIdx = exampleQuestion[exampleKey].examSubtestEssayKey.findIndex((val) => val.essay_score == 1 || val.essay_score == 2)
            setAbsoluteTrueEssay(exampleQuestion[exampleKey].examSubtestEssayKey[absoluteTrueIdx].essay_text)
            if (trueIdx >= 0) {
                setTrueEssay({
                    isTrue: true,
                    trueIdx: trueIdx
                })
            } else {
                setTrueEssay({
                    isTrue: false,
                    trueIdx: -1
                })
            }

        }
    }

    useEffect(() => {
        if (!state.isReady && !jwt.isReady) return
        axios.get(`${api_url}/exam/subtest/${state.userState.exam_subtest_id}/example`, {
            headers: {
                "Authorization": `Bearer ${jwt.jwtToken}`
            }
        }).then((res) => {
            // console.log("subtes", res.data)
            setExampleQuestion(res.data?.data)
            console.log("selesai save example question")
            setLoading(false)
        }).catch((err) => {
            console.log(err.response)
        })

    }, [state.isReady, jwt.isReady])

    useEffect(() => {
        if (!exam.isReady) return
        // console.log(exam.userExam.examSubtest[exam.examStatus.currentSubtest])
        console.log(exam)
    }, [exam.isReady])

    useEffect(() => {
        setTrueEssay({
            isTrue: false,
            trueIdx: -2
        })
        setEssayAnswer("")
        setSelectedRadio(-10)
        setTrueChoice(-10)
    }, [activeTab])

    useEffect(() => {
        // console.log("example question", exampleQuestion)
        // if (exampleQuestion.length > 0 && loading) {
        //     setLoading(false)
        // }

        // if (exampleQuestion.length == 0 && !loading && noExample == false) {
        //     setLoading(true)
        // }

    }, [exampleQuestion, loading])





    return (
        <Card shadow={"xl"} withBorder sx={(theme) => ({
            width: isPhone ? '100%' : '70%',
            backgroundColor: (theme.colorScheme == "dark" ? theme.colors.dark[9] : theme.colors.gray[2]),
            padding: isPhone ? 5 : 10,
            overflowX: isPhone ? "scroll" : "hidden",
            borderRadius: '15px'
        })}>
            <Stack spacing={5}>
                <Paper radius={"md"} p={"xs"} sx={(theme) => ({ backgroundColor: theme.colorScheme == "dark" ? theme.colors.orange[9] : theme.colors.orange[5] })}>
                    <Text size={30} color={"white"} weight={"bold"}>Petunjuk {exam.userExam.examSubtest[exam.examStatus.currentSubtest]?.subtest_name}</Text>
                </Paper>
                <Divider />
                <Box mx={10}>
                    <Tabs value={activeTab} defaultValue={"tentang"} onTabChange={setActiveTab}>
                        <Tabs.List>
                            <Tabs.Tab value="tentang" icon={<InfoCircle />}>Tentang Soal</Tabs.Tab  >
                            {exampleQuestion.map((data, key) => (
                                <Tabs.Tab key={key} value={`ex-${data.question_number}`}>Contoh {key + 1}</Tabs.Tab>
                            ))}
                            <Tabs.Tab value="kesiapan" icon={<AlertCircle />}>Kesiapan</Tabs.Tab>
                        </Tabs.List>
                        <Tabs.Panel value="tentang" my={15}>
                            <ScrollArea style={{ height: '60vh' }}>
                                <TypographyStylesProvider style={{ fontSize: '18px' }}>
                                    {parse(state.userState.examSubtest?.instruction ?? "-")}
                                </TypographyStylesProvider>
                            </ScrollArea>
                            <Divider my={"xs"} />
                            <Group position="right">
                                <Button color={"cyan"} onClick={() => {
                                    if (exampleQuestion.length > 0) { setActiveTab(`ex-${exampleQuestion[0]?.question_number}`) }
                                    else { setActiveTab('kesiapan') }
                                }}>Selanjutnya</Button>
                            </Group>
                        </Tabs.Panel>
                        {exampleQuestion.map((data, key) => (
                            <Tabs.Panel key={key} value={`ex-${data.question_number}`} my={15}>
                                <ScrollArea style={{ height: '60vh' }}>
                                    <TypographyStylesProvider style={{ fontSize: '18px' }}>
                                        {activeTab == `ex-${data.question_number}` && parse(data?.question_text ?? "-")}
                                    </TypographyStylesProvider>
                                    <Divider my={"xs"} />
                                    {data?.question_type == "PG" && (
                                        <Stack align={"start"} spacing={"sm"}>
                                            {data?.examSubtestChoice?.map((choice, key) => (
                                                <Box key={key} p={5} onClick={() => setSelectedRadio(key)}
                                                    sx={(theme) => ({
                                                        backgroundColor: trueChoice == key ? theme.colors.green[5] : selectedRadio == key && trueChoice >= 0 ? theme.colors.red[5] : "",
                                                        width: '100%',
                                                        borderRadius: '10px'
                                                    })}>
                                                    <Group>
                                                        <input style={{ transform: 'scale(1.3)' }} type={"radio"}
                                                            value={key}
                                                            onChange={() => setSelectedRadio(key)}
                                                            checked={selectedRadio == key}
                                                        />
                                                        <Text weight={"500"}>
                                                            {choice?.choice_alpha}
                                                        </Text>
                                                        <TypographyStylesProvider sx={{ '& p': { marginTop: 0, marginBottom: 0 }, fontSize: '18px' }}>
                                                            {parse(choice?.choice_text ?? "")}
                                                        </TypographyStylesProvider>
                                                    </Group>
                                                </Box>
                                            ))}
                                        </Stack>
                                    )}
                                    {data?.question_type == "MULTIPG" && (
                                        <Group >
                                            {data?.examSubtestChoice?.map((data, key) => (
                                                <Box key={key} sx={(theme) => ({
                                                    backgroundColor: revealMultiCorrect == false || selectedCheckbox[key] == null ? '' : selectedCheckbox[key] == false ? '' : selectedCheckbox[key] == data?.is_correct ? theme.colors.green[5] : theme.colors.red[5],
                                                    borderRadius: '10px'
                                                })}>
                                                    <Group px={2}>
                                                        <input style={{ transform: 'scale(1.3)' }} type={"checkbox"}
                                                            onChange={(e) => onCheckboxChange(key, e.currentTarget.checked)}
                                                            checked={selectedCheckbox[key] == null ? false : selectedCheckbox[key]}

                                                        />
                                                        <Text weight={"500"}>
                                                            {data?.choice_alpha}
                                                        </Text>
                                                        <TypographyStylesProvider sx={{
                                                            '& p': { marginTop: 0, marginBottom: 0 },
                                                            // fontSize: '28px'
                                                        }}>
                                                            {parse(data?.choice_text ?? "")}
                                                        </TypographyStylesProvider>
                                                    </Group>
                                                </Box>
                                            ))}
                                        </Group>
                                    )

                                    }
                                    {data?.question_type == "ESSAY" && (
                                        <div>
                                            <TextInput value={essayAnswer} onChange={(ev) => setEssayAnswer(ev.target.value)} label="Jawaban" />
                                            {trueEssay.trueIdx > -2 && (
                                                <Box>
                                                    {trueEssay.isTrue && (<Text color={"green"} weight={"500"}>JAWABAN ANDA BENAR!</Text>)}
                                                    {!trueEssay.isTrue && (
                                                        <>
                                                            <Text color={"red"} weight={"500"}>JAWABAN ANDA SALAH!</Text>
                                                            <Text weight={"500"}>Jawaban yang benar: {absoluteTrueEssay}</Text>
                                                        </>
                                                    )}
                                                </Box>
                                            )}
                                        </div>
                                    )}
                                    <Group position="right" my={"md"}>
                                        {(data.parameter == null || data.parameter == "" || data.parameter?.need_answer == false) && (
                                            <Button color={"orange"} onClick={() => checkAnswer(key, data?.question_type)}
                                                disabled={(data?.question_type == "PG" && selectedRadio < 0) || (data?.question_type == "ESSAY" && essayAnswer.length == 0) || (data?.question_type == "MULTIPG" && selectedCheckbox.length == 0)}>
                                                Cek Jawaban
                                            </Button>
                                        )}
                                        <Button color={"cyan"} onClick={() => {
                                            setActiveTab((key == exampleQuestion.length - 1 ? "kesiapan" : `ex-${exampleQuestion[key + 1].question_number}`))
                                            setRevealMultiCorrect(false)
                                            setSelectedCheckbox([])
                                        }}>Selanjutnya</Button>
                                    </Group>
                                </ScrollArea>
                            </Tabs.Panel>
                        ))}
                        <Tabs.Panel value="kesiapan" my={15}>
                            <Text>Ketika menjawab persoalan terakhir, maka tombol <b>SELESAI</b> akan tampak.
                                Tekan tombol tersebut untuk menyelesaikan Subtes ini.
                            </Text>
                            <Text>Apabila Anda sudah siap, tutup petunjuk ini dan waktu mulai berjalan.</Text>
                            <Stack align={"end"}><Button onClick={onStartTimeExam} color={"yellow"}>Tutup</Button></Stack>
                        </Tabs.Panel>
                    </Tabs>
                </Box>
            </Stack>
        </Card>
    )
}

export default TutorialExam