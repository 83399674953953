import { Button, Modal, TypographyStylesProvider } from "@mantine/core"
import { useContext, useState } from "react"
import { InfoCircle } from "tabler-icons-react"
import AuthContext from "../../../context/AuthContext"
import parse from "html-react-parser"

const Instruction = () => {
    const [open, setOpen] = useState(false)
    const { exam, state } = useContext(AuthContext)

    return (
        <>
            <Button fullWidth onClick={() => setOpen(!open)} rightIcon={<InfoCircle />} size="xs" color="teal">Instruksi</Button>
            <Modal
                opened={open}
                onClose={() => setOpen(!open)}
                title="Instruksi"
                overflow={"inside"}
                size={"xl"}
                centered>
                <TypographyStylesProvider sx={{
                    '& p': { marginTop: 0, marginBottom: 0 },
                    // fontSize: '32px'
                }}>
                    {parse(exam.userExam.examSubtest[exam.examStatus.currentSubtest]?.instruction ?? "")}
                </TypographyStylesProvider>
            </Modal>
        </>
    )
}

export default Instruction