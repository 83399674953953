import CryptoJS from "crypto-js"

const encryption = {
    AESencrypt: (message, key) => {
        return CryptoJS.AES.encrypt(message, key).toString()
    },
    AESdecrypt: (message, key) => {
        try {
            return CryptoJS.AES.decrypt(message, key).toString(CryptoJS.enc.Utf8)
        } catch (err) {
            throw new Error("Payload tampered!")
        }
    }
}

export default encryption