import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { io } from "socket.io-client";
import { api_url } from "../../constant/api";
import const_hasher from "../../constant/hasher";
import encryption from "../../helper/encryption";
const SocketContext = createContext({
    stream: null,
    socketIo: null
})

const WebcamContext = ({ children }) => {
    const [cookies, _, __] = useCookies(['psychology'])
    const [socketIo, setSocketIo] = useState(io(api_url, {
        autoConnect: false,
        transports: ['websocket'],
        query: {
            "accountType": "user",
            "token": encryption.AESdecrypt(cookies['psychology'], const_hasher)
        }
    }))
    const [stream, setStream] = useState()

    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const record = (stream, ms) => {
        var rec = new MediaRecorder(stream),
            data = [];
        rec.ondataavailable = (e) => data.push(e.data);
        rec.start();
        var stopped = new Promise(
            (y, n) => ((rec.onstop = y), (rec.onerror = (e) => n(e.error || e.name)))
        );
        return Promise.all([stopped, wait(ms).then(() => rec.stop())]).then(() => data);
    };

    useEffect(() => {
        socketIo.connect()
        socketIo.on('connect', (data) => {
            console.log("socket connected");
        })

        socketIo.on('cek', (data) =>{
            console.log(data)
        })

        // navigator.mediaDevices.getUserMedia({ video: true })
        //     .then((stream) => {
        //         setInterval(() => {
        //             record(stream, 100).then((recording) => {
        //                 socketIo.emit("streamer", {
        //                     id: encryption.AESdecrypt(cookies['psychology'], const_hasher),
        //                     stream: recording
        //                 })
        //             })
        //         }, 100);
        //     })
    }, [])




    return (<SocketContext.Provider value={{ stream: stream, socketIo: socketIo }}>
        {children}
    </SocketContext.Provider>)
}

export {
    WebcamContext,
    SocketContext
}