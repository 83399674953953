import React, { Suspense, useEffect } from "react";
import { useCookies } from "react-cookie"
import { Navigate, Route, Routes } from "react-router-dom"
import AppShellAdmin from "./components/AppShellAdmin"
import { WebcamContext } from "./context/webcam/WebcamContext";
import LiveStream from "./pages/admin/livestream/LiveStream";
import axios from "axios"
import { api_url } from "./constant/api";
import const_hasher from "./constant/hasher";
import encryption from "./helper/encryption";
import { useNavigate } from "react-router-dom"
import { getHotkeyHandler } from "@mantine/hooks"
import ExamPage from "./pages/user/ExamPage";
import { Center, Loader, Stack, Text } from "@mantine/core";
import AuthContext from "./context/AuthContext";
import { useState } from "react";
import GreetingsPage from "./pages/user/GreetingsPage";
import { AdminContext } from "./context/AdminContext";

const ListAkun = React.lazy(() => import('./pages/admin/akun/ListAkun'))
const ListAkunAdmin = React.lazy(() => import('./pages/admin/akun/ListAkunAdmin'))
const DashboardAdmin = React.lazy(() => import('./pages/admin/DashboardAdmin'))
const LoginAdminPage = React.lazy(() => import('./pages/admin/LoginAdminPage'))
const LoginUserPage = React.lazy(() => import('./pages/user/LoginUserPage'))
const PsikotesDashboard = React.lazy(() => import('./pages/admin/psikotes/PsikotesDashboard'))
const PsikotesManager = React.lazy(() => import('./pages/admin/psikotes/PsikotesManager'))
const NormsDashboard = React.lazy(() => import('./pages/admin/norms/NormsDashboard'))
const RuleDashboard = React.lazy(() => import('./pages/admin/norms/rules/RuleDashboard'))
const AgesDashboard = React.lazy(() => import('./pages/admin/norms/ages/AgesDashboard'))
const RuleDetail = React.lazy(() => import('./pages/admin/norms/rules/details/RuleDetail'))
const ScoreDashboard = React.lazy(() => import('./pages/admin/norms/scores/ScoreDashboard'))
const LaporanPage = React.lazy(() => import('./pages/admin/laporan/LaporanPage'))
const ListGroup = React.lazy(() => import('./pages/admin/akun/ListGroup'))
const ListAkunGroup = React.lazy(() => import('./pages/admin/akun/ListAkunGroup'))
const UserAnswer = React.lazy(() => import('./pages/admin/answer/UserAnswer'))

// user
const DashboardUser = React.lazy(() => import('./pages/user/DashboardUser'))

const NormaRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<NormsDashboard />} />
            <Route path="/:id/scores" element={<ScoreDashboard />} />
            <Route path="/rules" element={<RuleDashboard />} />
            <Route path="/rules/:id/detail" element={<RuleDetail />} />
            <Route path="/ages" element={<AgesDashboard />} />
        </Routes>
    )
}


const AdminRoute = () => {
    const [cookies, setCookie, _] = useCookies(['incipient'])
    const navigate = useNavigate()

    if (!cookies["incipient"]) {
        return <Navigate to={"/access/admin"} />
    }

    return (
        <AdminContext>
            <AppShellAdmin>
                <Routes>
                    <Route path="/dashboard" element={<DashboardAdmin />} />
                    <Route path="/psikotes" element={<PsikotesDashboard />} />
                    <Route path="/psikotes/:id" element={<PsikotesManager />} />
                    <Route path="/akun" element={<ListGroup />} />
                    <Route path="/akun/member/:id" element={<ListAkunGroup />} />
                    <Route path="/akun/admin" element={<ListAkunAdmin />} />
                    <Route path="/livestream" element={<LiveStream />} />
                    <Route path="/norma/*" element={<NormaRoute />} />
                    <Route path="/laporan" element={<LaporanPage />} />
                    <Route path="/answer/:id/:type" element={<UserAnswer />} />
                </Routes>
            </AppShellAdmin>
        </AdminContext>
    )
}

const UserRoute = () => {
    const [cookies, _, removeCookie] = useCookies(['psychology'])
    const [jwt, setJwt] = useState('')
    const [isJwtReady, setIsJwtReady] = useState(false)
    const [currentSubtest, setCurrentSubtest] = useState(0)
    const [userState, setUserState] = useState({
        answer: [],
        ended_at: null,
        examSubtest: null,
        examSubtestQuestion: null,
        exam_session_id: null,
        exam_subtest_id: null,
        exam_subtest_question_id: null,
        id: 0,
        started_at: null,
        status: "",
        user_exam_id: null
    })
    const [isUserStateReady, setIsUserStateReady] = useState(false)
    const [exam, setExam] = useState({
        abbreviation: '',
        examSubtest: [],
        name: '',
        id: ''
    })
    const [examTypes, setExamTypes] = useState([])
    const [isExamReady, setIsExamReady] = useState(false)
    const [isBlur, setIsBlur] = useState(false)
    const navigate = useNavigate()

    const refreshState = async () => {
        setIsUserStateReady(false)
        try {
            let res = await axios.get(`${api_url}/exam/user/get-temp`, {
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })

            setUserState(res.data.data)
            setIsUserStateReady(true)
        }
        catch (err) {
            console.log(err.response.status)
        }
    }

    const refreshExamTypes = async () => {
        axios.get(`${api_url}/exam/session-type`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        }).then((res) => {
            // console.log(res.data.data?.examType)
            setExamTypes(res.data?.data?.exam)
        }).catch((err) => {
            console.log(err.response)
        })
    }

    const refreshUserExam = async () => {
        let availableExamLeft = examTypes.filter((data) => data.is_done == 0)
        try {
            let res = await axios.get(`${api_url}/exam/session-subtest`, {
                params: {
                    type: availableExamLeft[0].examType.id
                },
                headers: {
                    "Authorization": `Bearer ${jwt}`
                }
            })
            setExam(res.data.data?.examType)
            setIsExamReady(true)
            return res.data.data?.examType
        } catch (err) {
            console.log(err.response)
        }

    }

    useEffect(() => {
        document.body.addEventListener('keyup', (e) => {
            console.log(e.metaKey, e.key)
            if (e.metaKey || e.key == "PrintScreen" || e.key == "Meta") {
                removeCookie('psychology', {
                    path: '/'
                })
                navigate('/')
            }
        })

        document.body.addEventListener("keyup", (e) => {
            if (e.keyCode == "14") {
                removeCookie('psychology', {
                    path: '/'
                })
                navigate('/')
            }
        })


        window.onblur = (e) => {
            setIsBlur(true)
        }

        window.onfocus = (e) => {
            setIsBlur(false)
        }

        if (cookies["psychology"]) {
            setJwt(encryption.AESdecrypt(cookies['psychology'], const_hasher))
            setIsJwtReady(true)
        }
    }, [])

    useEffect(() => {
        if (examTypes.length == 0) return
        console.log(examTypes)

        let activeExam = examTypes.find((exam) => exam.is_done == 0)

        if (activeExam == undefined) {
            removeCookie('psychology', {
                path: '/'
            })
            navigate('/')
            return
        }

        console.log(activeExam)

        axios.get(`${api_url}/exam/session-subtest`, {
            params: {
                type: activeExam.examType.id
            },
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        }).then((res) => {
            // console.log(res.data.data?.examType)
            setExam(res.data.data?.examType)
            setIsExamReady(true)
        }).catch((err) => {
            console.log(err.response)
        })
    }, [examTypes, setExamTypes])

    useEffect(() => {
        if (!isJwtReady) return
        axios.get(`${api_url}/exam/session-type`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        }).then((res) => {
            // console.log(res.data.data?.examType)
            setExamTypes(res.data?.data?.exam)
        }).catch((err) => {
            console.log(err.response)
        })


    }, [isJwtReady])

    useEffect(() => {
        if (jwt == '') return
        axios.get(`${api_url}/exam/user/get-temp`, {
            headers: {
                "Authorization": `Bearer ${jwt}`
            }
        })
            .then((res) => {
                setUserState(res.data.data)
                setIsUserStateReady(true)
            }).catch((err) => {
                console.log(err.response.status)
                setIsUserStateReady(true)
            })

    }, [jwt])

    useEffect(() => {
        if (!userState.exam_subtest_id && !isUserStateReady && !isExamReady) return
        if (exam.examSubtest.length == 0) return

        let idx = exam.examSubtest.findIndex(x => x.id == userState.exam_subtest_id)
        setCurrentSubtest(idx)

    }, [isUserStateReady, isExamReady])

    if (!cookies["psychology"]) {
        return <Navigate to={"/"} />
    }

    return (
        <WebcamContext>
            <AuthContext.Provider value={{
                jwt: {
                    jwtToken: jwt,
                    setJwtToken: setJwt,
                    isReady: isJwtReady
                },
                state: {
                    userState: userState,
                    setUserState: setUserState,
                    isReady: isUserStateReady,
                    refreshState: refreshState
                },
                exam: {
                    userExam: exam,
                    examStatus: {
                        currentSubtest: currentSubtest,
                        subtestCount: exam.examSubtest.length - 1
                    },
                    setUserExam: setExam,
                    setIsReady: setIsExamReady,
                    isReady: isExamReady,
                    examTypes: examTypes,
                    setExamTypes: setExamTypes,
                    refreshUserExam: refreshUserExam,
                    refreshExamTypes: refreshExamTypes
                }
            }}>
                <Routes>
                    <Route path="/welcome" element={<DashboardUser />} />
                    <Route path="/greetings" element={<GreetingsPage />} />
                    <Route path="/exam" element={<ExamPage />} />
                </Routes>
            </AuthContext.Provider>
            {isBlur && (
                <div style={{ position: 'absolute', top: 0, bottom: 0, width: '100%', backgroundColor: 'black' }}>
                    <Center style={{ width: '100vw', height: '100vh' }}>
                        <Stack spacing={"xs"} align={"center"}>
                            <Text weight={"bold"} color={"white"}>Dokumen Terproteksi</Text>
                            <Text color={"white"}>Silahkan arahkan mouse kembali ke website ini</Text>
                        </Stack>
                    </Center>
                </div>
            )}
        </WebcamContext>
    )
}

const Router = () => {

    // versioning
    useEffect(() => {
        axios.get(`${api_url}/version`).then((res) => {
            let version = localStorage.getItem("application_version")
            if (version == null || version != res.data?.version) {
                console.log('old application, clear cache')
                localStorage.setItem("application_version", res.data?.version)
                if ('caches' in window) {
                    caches.keys().then((names) => {
                        for (let name of names) {
                            caches.delete(name)
                        }
                    })
                }
                window.location.reload()
            }
        })

    }, [])
    return (
        <>
            <Suspense fallback={
                <Center style={{ width: '100%', height: '100vh' }}>
                    <Stack align={"center"}>
                        <Loader />
                        <Text size={"xl"} weight={"500"}>Memuat Komponen...</Text>
                    </Stack>
                </Center>}>
                <Routes>
                    <Route path="/admin/*" element={<AdminRoute />} />
                    <Route path="/user/*" element={<UserRoute />} />
                    <Route path="/access/admin" element={<LoginAdminPage />} />
                    <Route path="/" element={<LoginUserPage />} />
                </Routes>
            </Suspense>
        </>
    )
}

export default Router