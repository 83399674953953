import { Button, Group, Modal, Stack, Text } from "@mantine/core"
import { useContext, useEffect } from "react"
import AuthContext from "../../context/AuthContext"

const SubtesConfirm = ({ open, setOpen, saveAnswer, countNullAnswer, nullAnswers }) => {

    const {exam, state} = useContext(AuthContext)

    useEffect(() =>{
        if(!open) return
        countNullAnswer()
    }, [open])

    return (
        <Modal
            opened={open}
            onClose={() => setOpen(!open)}
            closeOnClickOutside={false}
            title="Konfirmasi"
            centered
        >
            <Text weight={"500"} size={"lg"}>Anda yakin ingin menyelesaikan Subtes ini?</Text>
            {nullAnswers > 0 && (
                <Text color={"red"} weight={500}>Terdapat {nullAnswers} soal yang belum dikerjakan!</Text>
            )}
            <Text>Setelah Anda menekan tombol <b>YA</b>, Anda tidak dapat kembali ke subtes ini lagi.</Text>
            <Stack align={"end"} mt={15}>
                <Group>
                    <Button onClick={() => setOpen(!open)}>TIDAK</Button>
                    <Button color={"red"} onClick={() => {saveAnswer();setOpen(!open)}}>YA</Button>
                </Group>
            </Stack>
        </Modal>
    )
}

export default SubtesConfirm