import { Box, ColorSwatch, useMantineTheme } from "@mantine/core"
import { useEffect, useState } from "react"

const ConnectivityStatus = ({ connectivity }) => {
    const [color, setColor] = useState("green")

    useEffect(() => {
        switch (connectivity) {
            case "online":
                setColor("green")
                return
            case "pending":
                setColor("yellow")
                return
            case "offline":
                setColor("red")
                return
        }
        console.log("cek connectivity", connectivity)
    }, [connectivity])

    useEffect(() =>{
        console.log(color)
    }, [color])

    const theme = useMantineTheme()
    return (
        <Box sx={{ position: "absolute", top: 7, left: 7 }}>
            <ColorSwatch size={20} color={theme.colors[color][5]} />
        </Box>
    )
}

export default ConnectivityStatus