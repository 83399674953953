import { Box, Button, Group, Image, Paper, Stack, Text, Title } from "@mantine/core"
import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import { logo } from "../../static/images"

const GreetingsPage = () => {
    const navigate = useNavigate()
    const { jwt, exam, state } = useContext(AuthContext)

    useEffect(() => {
        if (!state.isReady) return
        if (state.userState.status !== "" && exam.isReady) {
            return navigate('/user/exam', { replace: true })
        }
    }, [state.isReady, exam.isReady])

    return (
        <Box sx={(theme) => ({backgroundColor: theme.colors.orange[1], height: '100vh'})}>
            <Stack mx={"sm"}>
                <Group position="apart">
                    <Title>Welcome to Incipient</Title>
                    <Image src={logo} width="170px" />
                </Group>
                <Paper p={"sm"} withBorder radius={"lg"}>
                    <Stack align={"center"}>
                        <Title align="center" order={2}>Halo Apa Kabar?</Title>
                        <Text align="center" weight={"500"}>Selamat datang di Incipient Psychology Tools. Ini adalah sebuah Supervised Online Testing</Text>
                        <Text align="center" weight={"500"}>Sistem tes online yang tersupervisi dengan menggunakan sistem pemantauan jarak jauh – media daring. Aplikasi ini disusun dengan profesional teruji dalam bidang Psikologi dan IT sehingga aplikasi ini aman digunakan. Aplikasi ini dapat diakses dengan jaringan internet dan perangkat computer. Setiap jawaban akan tersimpan secara otomatis sehingga tidak perlu khawatir saat mengalami hambatan jaringan atau perangkat. Setiap tampilan tes di sistem online tidak bisa Screen shoot, atau copy paste, untuk menjaga kerahasiaan. Silahkan membaca, memahami, dan mengikuti panduan yang ada. </Text>
                        <Text align="center" weight={"500"}>Selamat mengerjakan tes, Jadilah dirimu sendiri supaya hasilnya optimal.
                        </Text>
                    </Stack>
                    <Group position="center" mt={"sm"}>
                        <Button onClick={() => navigate('/user/welcome')} color={"violet"}>Selanjutnya</Button>
                    </Group>
                </Paper>
            </Stack>

        </Box>

    )
}

export default GreetingsPage