import LZString from "lz-string"
import const_hasher from "../constant/hasher"
import encryption from "./encryption"
import CryptoJS from "crypto-js"

const secureStorage = {
    set: (key, data) => {
        const json = JSON.stringify(data)
        const enc = encryption.AESencrypt(json, const_hasher).toString()
        localStorage.setItem(key, enc);
        return 1
    },
    get: (key) => {
        try {
            const json = encryption.AESdecrypt(localStorage.getItem(key), const_hasher).toString(CryptoJS.enc.Utf8)
            const data = JSON.parse(json)

            return data
        } catch (err) {
            return null
        }
    },
    setCompress: (key, data) => {
        const json = JSON.stringify(data)
        const compressed = LZString.compressToUTF16(json).trim()
        localStorage.setItem(key, compressed);
        return 1;
    },
    getDecompress: (key) => {
        try {
            const compressed = localStorage.getItem(key)
            const json = LZString.decompressFromUTF16(compressed)
            const data = JSON.parse(json)

            return data
        } catch (err) {
            return null
        }
    },
    del: (key) => {
        localStorage.removeItem(key)
    },
    reset: ()=>{
        localStorage.removeItem("que")
        localStorage.removeItem("ans")
        console.log("successfully reset")
    }
}

export default secureStorage