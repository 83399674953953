import { createContext } from "react";

const AuthContext = createContext({
    jwt: {
        jwtToken: "",
        setJwtToken: () => { },
        isReady: false
    },
    state: {
        userState: {
            answer: [],
            ended_at: null,
            examSubtest: null,
            examSubtestQuestion: null,
            exam_session_id: null,
            exam_subtest_id: null,
            exam_subtest_question_id: null,
            id: 0,
            started_at: null,
            status: "",
            user_exam_id: null
        },
        setUserState: () => { },
        refreshState: async () => { },
        isReady: false
    },
    exam: {
        userExam: {
            abbreviation: '',
            examSubtest: [],
            name: '',
        },
        examStatus: {
            currentSubtest: '',
            subtestCount: '',
        },
        examTypes: [],
        setUserExam: () => { },
        setExamStatus: () => { },
        setIsReady: () => { },
        setExamTypes: () => { },
        refreshUserExam: async () => { },
        refreshExamTypes: async () => { },
        isReady: false
    }

})

export default AuthContext