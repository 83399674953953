import { Avatar, Box, Button, Card, Divider, Grid, Group, Image, Stack, Text, TextInput, Textarea, TypographyStylesProvider } from "@mantine/core"
import { useDebouncedValue, useInterval } from "@mantine/hooks"
import { useContext, useEffect, useRef, useState } from "react"
import AuthContext from "../../../context/AuthContext"
import parse from "html-react-parser"
import { logo } from "../../../static/images"
import { InfoCircle } from "tabler-icons-react"
import Instruction from "./Instruction"
import Numberer from "../../../helper/Numberer"
import { useCookies } from "react-cookie"
import secureStorage from "../../../helper/secureStorage"

const ExamTemplate = ({ setConfirm, seconds, questions, activeQuestion, setActiveQuestion, answers, updateAnswers, isPhone, psikotes, nullAnswers }) => {
    const [selectedRadio, setSelectedRadio] = useState("")
    const [selectedCheckbox, setSelectedCheckbox] = useState([])
    const [essayAnswer, setEssayAnswer] = useState("")
    const [essayDebounced] = useDebouncedValue(essayAnswer, 400)
    const [zeroMinutes, setZeroMinutes] = useState("")
    const [zeroSeconds, setZeroSeconds] = useState("")
    const [nextDisabledEssay, setNextDisabledEssay] = useState(true)
    const [nextDisableMultiPg, setNextDisableMultiPg] = useState(true)
    const [logoutSecond, setLogoutSecond] = useState(0);
    const interval = useInterval(() => setLogoutSecond((s) => s + 1), 1000)
    const [cookies, _, removeCookie] = useCookies(['psychology'])
    const cardRef = useRef(null);
    const longtextRef = useRef();

    const { state, exam } = useContext(AuthContext)

    const toPrevious = () => {
        setActiveQuestion(activeQuestion - 1)
    }

    const toNextMultiPg = () => {
        if (activeQuestion == questions.length - 1) return
        setSelectedCheckbox([])
        setNextDisableMultiPg(true)
        setActiveQuestion(activeQuestion + 1)
        cardRef.current.scroll({
            top: 0,
        })
    }

    const toNext = () => {
        if (activeQuestion == questions.length - 1) return
        setActiveQuestion(activeQuestion + 1)
        cardRef.current.scroll({
            top: 0,
        })
    }

    const saveAnswerPg = (answerId, activeQuestion, temp_choice) => {
        const ansTemplate = {}
        ansTemplate["exam_answer_type"] = questions[activeQuestion]?.question_type
        // ansTemplate["exam_answer_text"] = null
        ansTemplate["exam_subtest_question_id"] = questions[activeQuestion]?.id
        ansTemplate["exam_subtest_choice_id"] = answerId
        ansTemplate["exam_session_id"] = state.userState.exam_session_id
        ansTemplate["exam_subtest_id"] = state.userState.exam_subtest_id
        ansTemplate["temp_choice"] = temp_choice

        let newAns = answers.slice()
        newAns[activeQuestion] = ansTemplate
        updateAnswers(newAns)
        setSelectedRadio(answerId)
        toNext()
    }

    const onCheckboxChange = (index, value) => {
        let temp = selectedCheckbox;
        temp[index] = value;
        console.log(temp)
        saveMultiAnswerPg(temp)
        setSelectedCheckbox(temp);
        disabledNextMultiPg()
    }

    const saveMultiAnswerPg = (temp) => {
        const ansArray = [];
        temp.map((val, index) => {
            if (val) {
                console.log(questions[activeQuestion]?.examSubtestChoice[index])
                const ansTemplate = {}
                ansTemplate["exam_answer_type"] = questions[activeQuestion]?.question_type
                // ansTemplate["exam_answer_text"] = null
                ansTemplate["exam_subtest_question_id"] = questions[activeQuestion]?.id
                ansTemplate["exam_subtest_choice_id"] = questions[activeQuestion]?.examSubtestChoice[index]?.id
                ansTemplate["exam_session_id"] = state.userState.exam_session_id
                ansTemplate["exam_subtest_id"] = state.userState.exam_subtest_id
                ansTemplate["temp_choice"] = questions[activeQuestion]?.examSubtestChoice[index]?.choice_alpha
                ansArray.push(ansTemplate)
            }
        })

        let newAns = answers.slice()
        newAns[activeQuestion] = ansArray
        updateAnswers(newAns)
    }

    const disabledNextMultiPg = () => {
        console.log("helooooooo")
        if (activeQuestion == questions.length - 1) {
            setNextDisableMultiPg(true)
        } else {
            console.log("BERHITUNG")
            let checked = 0
            selectedCheckbox.forEach((data) => {
                if (data == true) checked++;
            })

            setNextDisableMultiPg(checked < 2)
        }
    }


    useEffect(() => {
        if (longtextRef.current == null) return
        longtextRef.current.scrollTop = longtextRef.current.scrollHeight
    }, [longtextRef.current])

    useEffect(() => {
        if (essayDebounced == "") return
        const ansTemplate = {}
        ansTemplate["exam_answer_type"] = questions[activeQuestion]?.question_type
        ansTemplate["exam_subtest_answer_text"] = essayDebounced
        ansTemplate["exam_subtest_question_id"] = questions[activeQuestion]?.id
        ansTemplate["exam_session_id"] = state.userState.exam_session_id
        ansTemplate["exam_subtest_id"] = state.userState.exam_subtest_id

        let newAns = answers.slice()
        newAns[activeQuestion] = ansTemplate
        updateAnswers(newAns)
        setNextDisabledEssay(false)
    }, [essayDebounced])

    useEffect(() => {
        if (essayDebounced == "") return
        if (nextDisabledEssay) return
        setNextDisabledEssay(true)
    }, [essayAnswer])

    useEffect(() => {
        if (!answers[activeQuestion]) {
            setSelectedCheckbox([])
            return
        }
        if (answers[activeQuestion]?.exam_answer_type == "PG") {
            setSelectedRadio(answers[activeQuestion]?.exam_subtest_choice_id)
        } else if (answers[activeQuestion]?.length > 0) {
            console.log("mashok multipg")
            const alphabetToIndex = ["A", "B", "C", "D", "E", "F"]
            const tempCheckbox = []
            answers[activeQuestion]?.map((data, index) => {
                tempCheckbox[alphabetToIndex.findIndex(i => i == data?.temp_choice)] = true;
                setSelectedCheckbox(tempCheckbox)
            })
        }
        else {
            console.log("mashok gaada")
            setSelectedCheckbox([])
            setEssayAnswer("")
        }
    }, [activeQuestion])

    useEffect(() => {
        let min = Math.floor(seconds % 3600 / 60);
        let sec = Math.floor(seconds % 3600 % 60);

        setZeroMinutes(min < 10 ? "0" + min : min)
        setZeroSeconds(sec < 10 ? "0" + sec : sec)
    }, [seconds])

    useEffect(() => {
        console.log("logout: ", logoutSecond)
        if (logoutSecond >= 3) {
            secureStorage.reset()
            removeCookie('psychology', {
                path: '/'
            })
            return
        }
    }, [logoutSecond])

    return (
        <Grid p={"sm"}>
            <Grid.Col mb={"sm"}>
                <Grid>
                    <Grid.Col p={0} span={isPhone ? 12 : 9}>
                        <Card mx={"xs"} withBorder>
                            <Group>
                                <Text size={isPhone ? "sm" : "xl"} weight={"500"}>Subtes {state.userState.examSubtest.subtest_name} </Text>
                                <Divider orientation="vertical" size={isPhone ? "sm" : "md"} />
                                <Text weight={"500"} size={isPhone ? "sm" : "md"}>
                                    Soal ke- {
                                        psikotes?.abbreviation == "DISC" ? (
                                            (activeQuestion + 1) % 2 == 0 ? ((activeQuestion + 1) / 2) + "B" : (Math.ceil((activeQuestion + 1) / 2) + "A")
                                        ) : (activeQuestion + 1)
                                    }</Text>
                            </Group>

                        </Card>
                    </Grid.Col>
                    {isPhone && (
                        <Grid.Col p={0} m={0} span={12}>
                            <Card p={"xs"} sx={(theme) => ({ backgroundColor: theme.colors.red[4] })} mx={"xs"} withBorder>
                                <Stack sx={{ height: '100%' }} align={"center"} justify={"center"}>
                                    {seconds > -90 && <Text size={isPhone ? "sm" : "xl"} weight={"500"}>Sisa Waktu {zeroMinutes} : {zeroSeconds}</Text>}
                                    {seconds < -90 && <Text size={isPhone ? "sm" : "xl"} weight={"500"}>Sisa Waktu ---</Text>}
                                </Stack>
                            </Card>
                        </Grid.Col>
                    )}
                    {!isPhone && (
                        <Grid.Col p={0} span={3}>
                            <Card sx={(theme) => ({ backgroundColor: theme.colors.red[4] })} withBorder>
                                <Stack sx={{ height: '100%' }} align={"center"} justify={"center"}>
                                    {seconds > -90 && <Text size={"xl"} weight={"500"}>Sisa Waktu {zeroMinutes} : {zeroSeconds}</Text>}
                                    {seconds < -90 && <Text size={"xl"} weight={"500"}>Sisa Waktu ---</Text>}
                                </Stack>
                            </Card>
                        </Grid.Col>
                    )}
                </Grid>

            </Grid.Col>
            {isPhone && (
                <Grid.Col p={0} span={12} mb={"sm"}>
                    <Card mx={"xs"} p={"xs"}>
                        <Stack>
                            <Card m={0} sx={{ maxHeight: '300px', overflowX: 'auto' }} withBorder>
                                <Text size={"sm"} weight={"500"}>Daftar Soal</Text>
                                <Divider my={"xs"} />
                                <Group noWrap position="left">
                                    {questions?.map((data, key) => (
                                        <Avatar size={"sm"} variant={key == activeQuestion ? 'outline' : 'filled'}
                                            color={questions[key]?.question_type == "MULTIPG" ? answers[key]?.length > 0 ? 'green' : 'blue'
                                                : questions[key] && answers[key] ? 'green' : 'blue'}
                                            // color={questions[key] && answers[key] ? 'green' : 'blue'}
                                            onClick={() => { setActiveQuestion(key) }} key={key}>
                                            {Numberer(psikotes?.abbreviation, key + 1)}
                                        </Avatar>
                                    ))}
                                </Group>
                            </Card>
                            <Grid>
                                <Grid.Col span={6}>
                                    <Button fullWidth size="xs" color={"violet"} onClick={() => setConfirm(true)}
                                        onMouseDown={() => interval.start()}
                                        onMouseUp={() => {
                                            interval.stop();
                                            setLogoutSecond(0)
                                        }}
                                        disabled={
                                            (psikotes?.abbreviation == "DISC" || psikotes.abbreviation == "KOSTICK" || psikotes.abbreviation == "MBTI") && nullAnswers != 0
                                        } >Selesai</Button>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <Instruction />
                                </Grid.Col>
                            </Grid>
                        </Stack>
                    </Card>
                </Grid.Col>
            )}
            <Grid.Col>
                <Grid sx={{ height: '100%' }}>
                    <Grid.Col p={0} span={isPhone ? 12 : 9}>
                        <Card ref={cardRef} mx={"xs"} withBorder sx={{ maxHeight: isPhone ? '50vh' : '70vh', overflowY: 'auto' }}>
                            <Stack spacing={"sm"}>
                                <TypographyStylesProvider sx={{
                                    '& p': { marginTop: 0, marginBottom: 0 },
                                    // fontSize: '32px'
                                }}>
                                    {parse(questions[activeQuestion]?.question_text ?? "")}
                                </TypographyStylesProvider>
                                <Divider variant="dashed" />
                                {questions[activeQuestion]?.question_type == "PG" && questions[activeQuestion]?.position != "HORIZONTAL" && (
                                    <Stack align={"start"} spacing={"sm"}>
                                        {questions[activeQuestion]?.examSubtestChoice?.map((data, key) => (
                                            <Box key={key} onClick={() => {
                                                if (psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha) return
                                                saveAnswerPg(data.id, activeQuestion, data?.choice_alpha)
                                            }}>
                                                <Group px={2}>
                                                    <input style={{ transform: 'scale(1.3)' }} type={"radio"} value={data.id}
                                                        onChange={(e) => setSelectedRadio(e.target.value)}
                                                        checked={selectedRadio == data.id}
                                                        disabled={psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha}
                                                    />
                                                    <Text weight={"500"}>
                                                        {data?.choice_alpha}
                                                    </Text>
                                                    <TypographyStylesProvider sx={{
                                                        '& p': { marginTop: 0, marginBottom: 0 },
                                                        // fontSize: '28px'
                                                    }}>
                                                        {parse(data?.choice_text ?? "")}
                                                    </TypographyStylesProvider>
                                                </Group>
                                            </Box>
                                        ))}
                                    </Stack>
                                )}

                                {questions[activeQuestion]?.question_type == "MULTIPG" && questions[activeQuestion]?.position != "HORIZONTAL" && (
                                    <Stack align={"start"} spacing={"sm"}>
                                        {questions[activeQuestion]?.examSubtestChoice?.map((data, key) => (
                                            <Box key={key} onClick={() => {
                                                if (psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha) return
                                                // saveAnswerPg(data.id, activeQuestion, data?.choice_alpha)
                                            }}>
                                                <Group px={2}>
                                                    <input style={{ transform: 'scale(1.3)' }} type={"checkbox"} value={data.id}
                                                        onChange={(e) => onCheckboxChange(key, e.currentTarget.checked)}
                                                        checked={selectedCheckbox[key]}
                                                        disabled={psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha}
                                                    />
                                                    <Text weight={"500"}>
                                                        {data?.choice_alpha}
                                                    </Text>
                                                    <TypographyStylesProvider sx={{
                                                        '& p': { marginTop: 0, marginBottom: 0 },
                                                        // fontSize: '28px'
                                                    }}>
                                                        {parse(data?.choice_text ?? "")}
                                                    </TypographyStylesProvider>
                                                </Group>
                                            </Box>
                                        ))}
                                    </Stack>
                                )}

                                {questions[activeQuestion]?.question_type == "MULTIPG" && questions[activeQuestion]?.position == "HORIZONTAL" && (
                                    <Group>
                                        {questions[activeQuestion]?.examSubtestChoice?.map((data, key) => (
                                            <Box key={key} onClick={() => {
                                                if (psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha) return
                                                // saveAnswerPg(data.id, activeQuestion, data?.choice_alpha)
                                            }}>
                                                <Group px={2}>
                                                    <input style={{ transform: 'scale(1.3)' }} type={"checkbox"} value={data.id}
                                                        onChange={(e) => onCheckboxChange(key, e.currentTarget.checked)}
                                                        checked={selectedCheckbox[key]}
                                                        disabled={psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha}
                                                    />
                                                    <Text weight={"500"}>
                                                        {data?.choice_alpha}
                                                    </Text>
                                                    <TypographyStylesProvider sx={{
                                                        '& p': { marginTop: 0, marginBottom: 0 },
                                                        // fontSize: '28px'
                                                    }}>
                                                        {parse(data?.choice_text ?? "")}
                                                    </TypographyStylesProvider>
                                                </Group>
                                            </Box>
                                        ))}
                                    </Group>
                                )}

                                {questions[activeQuestion]?.question_type == "PG" && questions[activeQuestion]?.position == "HORIZONTAL" && (
                                    <Group>
                                        {questions[activeQuestion]?.examSubtestChoice?.map((data, key) => (
                                            <Box key={key} onClick={() => {
                                                if (psikotes?.abbreviation == "DISC" && ((activeQuestion + 1) % 2 == 0) && answers[activeQuestion - 1]?.temp_choice == data?.choice_alpha) return
                                                saveAnswerPg(data.id, activeQuestion, data?.choice_alpha)
                                            }}>
                                                <Group px={2}>
                                                    <input style={{ transform: 'scale(1.3)' }} type={"radio"} value={data.id}
                                                        onChange={(e) => setSelectedRadio(e.target.value)}
                                                        checked={selectedRadio == data.id}
                                                    />
                                                    <Text weight={"500"}>
                                                        {data?.choice_alpha}
                                                    </Text>
                                                    <TypographyStylesProvider sx={{
                                                        '& p': { marginTop: 0, marginBottom: 0 },
                                                        // fontSize: '28px'
                                                    }}>
                                                        {parse(data?.choice_text ?? "")}
                                                    </TypographyStylesProvider>
                                                </Group>
                                            </Box>
                                        ))}
                                    </Group>
                                )}

                                {questions[activeQuestion]?.question_type == "ESSAY" && (
                                    <>
                                        <div key={answers[activeQuestion]?.exam_subtest_answer_text}>
                                            <TextInput type={questions[activeQuestion]?.parameter?.only_number == true ? "number" : "text"}
                                                description={questions[activeQuestion]?.parameter?.only_number == true ? "hanya jawaban angka" : ""}
                                                autoFocus defaultValue={answers[activeQuestion]?.exam_subtest_answer_text} onChange={(ev) => setEssayAnswer(ev.currentTarget.value)} label="Jawaban" />
                                        </div>
                                        <Group position="right">
                                            <Button onClick={toNext} disabled={nextDisabledEssay || activeQuestion == questions.length - 1}>Berikutnya</Button>
                                        </Group>
                                    </>
                                )}

                                {questions[activeQuestion]?.question_type == "LONGESSAY" && (
                                    <>
                                        <div key={activeQuestion}>
                                            <Textarea type={questions[activeQuestion]?.parameter?.only_number == true ? "number" : "text"}
                                                minRows={5}
                                                maxRows={10}
                                                ref={longtextRef}
                                                onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
                                                description={questions[activeQuestion]?.parameter?.only_number == true ? "hanya jawaban angka" : ""}
                                                autoFocus defaultValue={answers[activeQuestion]?.exam_subtest_answer_text} onChange={(ev) => setEssayAnswer(ev.currentTarget.value)} label="Jawaban" />
                                        </div>
                                        <Group position="right">
                                            <Button onClick={toNext} disabled={nextDisabledEssay || activeQuestion == questions.length - 1}>Berikutnya</Button>
                                        </Group>
                                    </>
                                )}
                                {questions[activeQuestion]?.question_type == "MULTIPG" && (
                                    <>
                                        <Group position="right">
                                            <Button onClick={toNextMultiPg} disabled={nextDisableMultiPg}>Berikutnya</Button>
                                        </Group>
                                    </>
                                )}
                            </Stack>
                        </Card>
                    </Grid.Col>

                    {!isPhone && (
                        <Grid.Col p={0} span={3}>
                            <Card>
                                <Stack>
                                    <Card m={0} sx={{ maxHeight: '300px', overflowY: 'auto' }} withBorder>
                                        <Text weight={"500"}>Daftar Soal</Text>
                                        <Divider my={"sm"} />
                                        <Group position="left">
                                            {questions?.map((data, key) => (
                                                <Avatar variant={key == activeQuestion ? 'outline' : 'filled'}
                                                    color={questions[key]?.question_type == "MULTIPG" ? answers[key]?.length > 0 ? 'green' : 'blue'
                                                        : questions[key] && answers[key] ? 'green' : 'blue'}
                                                    onClick={() => { setActiveQuestion(key) }} key={key}>
                                                    {Numberer(psikotes?.abbreviation, key + 1, questions.length)}
                                                </Avatar>
                                            ))}
                                        </Group>
                                    </Card>
                                    <Button onMouseDown={() => interval.start()}
                                        onMouseUp={() => {
                                            interval.stop();
                                            setLogoutSecond(0)
                                        }} color={"violet"} onClick={() => setConfirm(true)} disabled={
                                            (psikotes?.abbreviation == "DISC" || psikotes.abbreviation == "KOSTICK" || psikotes.abbreviation == "MBTI" || psikotes.abbreviation == "SDS") && nullAnswers != 0
                                        }>Selesai</Button>
                                    <Instruction />
                                </Stack>
                            </Card>
                        </Grid.Col>
                    )}
                </Grid>
            </Grid.Col>
            {/* <Grid.Col span={9} sx={(theme) => ({ padding: 10 })}>
                <Stack sx={{ height: '100%' }} justify={"flex-start"}>
                    <Card m={0} withBorder>
                        Heading Soal
                    </Card>
                    <Card m={0} withBorder sx={{ height: '10%', overflowY: 'auto' }}>
                        {Array.from(Array(40).keys()).map((data) => (
                            <Avatar>{data + 1}</Avatar>
                        ))}
                    </Card>
                </Stack>
            </Grid.Col>
            <Grid.Col span={3} sx={(theme) => ({ padding: 10 })}>
                <Stack sx={{ height: '100%' }} justify={"flex-start"}>
                    <Card m={0} sx={(theme) => ({ backgroundColor: theme.colors.red[4] })} withBorder>
                        <Stack sx={{ height: '100%' }} align={"center"} justify={"center"}>
                            <Text size={"xl"} weight={"500"}>Sisa Waktu 05:00</Text>
                        </Stack>
                    </Card>
                    <Card m={0} sx={{ height: '50%', overflowY: 'auto' }} withBorder>
                        <Group position="apart">
                            {Array.from(Array(40).keys()).map((data) => (
                                <Avatar>{data + 1}</Avatar>
                            ))}
                        </Group>
                    </Card>
                    <Card m={0} p={0} withBorder sx={{ height: '30%' }}>
                        <Group position="center"> <Image width={"100px"} src={logo} /></Group>
                    </Card>
                </Stack>
            </Grid.Col> */}
        </Grid>
    )
}

export default ExamTemplate