import { Button } from "@mantine/core"
import { useNavigate } from "react-router-dom"

const MenuButton = ({to, children}) => {
    const navigate = useNavigate()

    return (
        <Button variant="outline" onClick={() => navigate(to)}>
            {children}
        </Button>
    )
}

export default MenuButton