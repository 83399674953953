
const Numberer = (abbreviation, number, totalQuestion) => {
    switch (abbreviation) {
        case "DISC":
            if (number % 2 == 0) {
                return (number / 2) + "B"
            } else {
                return Math.ceil(number / 2) + "A"
            }
        case "SDS":
            let kelompokHuruf = ['R', 'I', 'A', 'S', 'E', 'C']
            let jumlahSoalKelompok = totalQuestion / kelompokHuruf.length;
            let kelIndex = Math.ceil(number / jumlahSoalKelompok) - 1;
            let rightNumber = Math.ceil(number - (kelIndex * jumlahSoalKelompok));

            return `${kelompokHuruf[kelIndex]}-${rightNumber}`
        default:
            return number
    }
}

export default Numberer